@charset "UTF-8";

@import "custom";
@import "~bootstrap/scss/bootstrap";
@import "~eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css";
@import "~font-awesome/css/font-awesome.css";
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";
@import "~plyr/dist/plyr.css";
@import "~tui-date-picker/dist/tui-date-picker.css";

/* bootstrap 3 to 4 migration */

.img-responsive {
   @extend .img-fluid;
}
.center-block {
   @extend .d-block, .mx-auto;
}

/* end of bootstrap 3 to 4 migration */

.pagination {
   .page-item {
      &.active {
         .page-link {
            background-color: #3eb16c;
            border-color: #48af8d !important;
         }
      }
   }
}

.gap-10 {
   gap: 10px !important;
}
.gap-20 {
   gap: 20px !important;
}

.gap-30 {
   gap: 30px !important;
}

.gap-40 {
   gap: 40px !important;
}

.dropdown-menu {
   .dropdown-item {
      &.active {
         background-color: $logo1;
      }
   }
}

.pagination {
   .page-link {
      border: none !important;
   }
}

.invalid-feedback {
   display: block;
}

.font-weight-bold {
   font-weight: 700;
}

// slick carousel
.slick-dots {
   bottom: -10px;
}

// pagination
.pagination > li > a, .pagination > li > span {
   color: #176f67;
}

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
   z-index: 3;
   color: #fff;
   cursor: default;
   background-color: #3eb16c;
   border-color: #48af8d;
}

.bg-orange {
   background-color: $logo1;
}

.bg-sand {
   background-color: $sand-color;
}

.bg-brown {
   background-color: $logo5;
}

.bg-white {
   background-color: #FFFFFF;
}

.bg-pattern-1 {
   background: $sand-color url('/images/bg-pattern-1.png') repeat;
}

::selection {
   background: #A5E850;
   color: #000000;
   text-shadow: none;
}

.d-inline-flex {
   display: inline-flex;
}

.align-items-center {
   align-items: center;
}

.btn {
   .feather {
      width: 1em;
      height: 1em;
   }
}

.w-100 {
   width: 100% !important;
}

p {
   word-break: break-all;
}

button {
   &:hover {
      cursor: pointer;
   }
}

.btn-width {
   min-width: 80px;
}

.bg-orange {
   transition: all .3s ease;
   color: #FFFFFF;

   &:hover {
      background-color: #FFBA00;
      color: $dark;
   }
}

.mj {
   font-family: 'MaruBuri', 'Iropke Batang', serif !important;
}

button.bg-white {
   transition: all .3s ease;
   color: $default-color;

   &:hover {
      background-color: $default-color;
      color: $dark;
   }
}

@mixin text-hover-animation($underlineColor) {
   color: $text-color;
   background-image: linear-gradient(180deg, transparent 65%, #E9FF3E 0);
   background-size: 0 80%;
   background-repeat: no-repeat;
   text-decoration: none;
   transition: background-size .45s ease;
   &:hover {
      background-size: 200% 80%;
   }
}

body {
   font-size: 16px;
   box-sizing: border-box;
   background-color: #FFFFFF;
}

body, h1, h2, h3, h4, h5, h6, div, li, span, p, a, div, blockquote {
   font-family: $font-family-sans-serif;
}

.entry-content {
   p, li {
      font-family: $font-family-serif;
      font-weight: 400;
   }
}

a {
   color: $text-color;

   &:visited, &:active, &:link {
      text-decoration: none;
      color: $default-color;
   }

   &:focus {
      outline: none;
   }
}

/*a.btn {
  color: #FFFFFF;
}*/

a.btn-default {
   color: $text-color;
}

button {
   &:focus {
      outline: none;
   }
}

@media(max-width: 414px),
(max-width: 414px) and (-webkit-min-device-pixel-ratio: 1.3),
(max-width: 414px) and (-webkit-min-device-pixel-ratio: 2),
(max-width: 414px) and (-webkit-min-device-pixel-ratio: 3) {
   h3 {
      line-height: 1.4em;
   }
}

.highlight {
   display: inline-block;
   position: relative;
   font-weight: 700;
   font-size: inherit;
   font-family: inherit;

   &::before {
      content: '';
      display: block;
      width: 100%;
      height: .3em;
      background-color: #EFFF00;
      opacity: 1;
      left: 0;
      bottom: 0;
      position: absolute;
   }
}

// 페이지 레이아웃
#page {
   position: relative;
}

#page-header {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   max-height: 350px;
   position: relative;
   overflow: hidden;
   z-index: 1;
   @include media-breakpoint-down(sm) {
      h1 {
         font-size: 1.3em;
         font-weight: 700;
      }
   }

   .title {
      padding: 20px 35px;
      border-radius: 40px;
      color: #FFFFFF;
      text-shadow: 0 0 8px #000000;

      small {
         color: #FFFFFF;
         text-shadow: 0 0 8px #000000;
      }
   }

   .bg-wrap {
      background-color: #EEEEEE;
      background-repeat: no-repeat;
      background-position: 50% 50% !important;
      position: relative;
      overflow: hidden;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      display: flex;
      justify-content: center;
      @include media-breakpoint-down(sm) {
         background-size: cover;
         background-position: 50% 0 !important;
      }

      > img {
         width:100%;
      }

      .container {
         position: relative;

         .sermon-info {
            position: absolute;
            display: flex;
            flex-direction: column;
            width: 400px;
            right: 0;
            top: 0;
            height: 100%;
            background: $light-grey;
            padding: 40px;

            h1 {
               font: 32px/40px 'Iropke Batang', serif;
               margin: 0;
               color: $dark;
            }

            .divide {
               width: 40px;
               height: 4px;
               background-color: $dark;
               margin: 30px 3px;
            }

            p {
               font: 16px/1.8em 'Iropke Batang', serif;
               color: $dark;
            }
         }
      }
   }

   .title-wrap {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 3;
      display: flex;
      justify-content: center;
      align-items: center;
   }
}

#page-wrap {
   display: flex;
   padding-top: 40px;

   @include media-breakpoint-down(md) {
      flex-wrap: wrap;
      padding-top: 0;
   }

   main {
      flex-basis: calc(100% - 140px);
      padding-right: 40px;
      @include media-breakpoint-down(md) {
         flex-basis: 100%;
         padding-right: 0;
         min-height:500px;
         order: 2;
      }
      @include media-breakpoint-down(sm) {
         position: relative;
         flex-basis: 100%;
         padding: 0;
         order: 2;
         min-height:500px;
      }
   }

   aside {
      flex: none;
      width: 240px;
      @include media-breakpoint-down(md) {
         flex-basis: 100%;
         margin-top: 30px;
         margin-bottom: 30px;
         order: 1;
      }
   }
}

#page-wrap-full {
   display: flex;
   width: 100%;

   main {
      flex-basis: 100%;
      min-height:500px;
   }
}

.side-menu {
   h3 {
      color: $logo4;
      border-bottom: 2px solid $sand-color;
      padding-bottom: .3em;
      margin-bottom: .3em;
   }

   > ul {
      list-style-type: none;
      padding-left: 0;
      @include media-breakpoint-down(md) {
         display:grid;
         grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
         grid-gap: 10px;
      }
      @include media-breakpoint-down(sm) {
         display:grid;
         grid-template-columns: repeat(2, 1fr);
         grid-gap: 10px;
      }

      > li {
         border-bottom: 1px dashed $logo6;
         padding: .5em 1em;
         position: relative;

         &:hover {
            background-color: $logo1;
            transition:none;

            a {
               color: #FFFFFF;
               font-weight: 600;
            }
         }

         @include media-breakpoint-down(md) {
            border: 1px solid $menu-light-grey;
         }
         @include media-breakpoint-down(sm) {
            flex-basis: calc(50% - 10px);
            font-size: 14px;
            &:nth-child(2n) {
               margin-right: 0;
            }
         }

         a {
            padding: 9px 15px 9px 5px;
            color: $logo5;
            transition: all .4s ease;

            @include media-breakpoint-down(md) {
               position:static;
            }

            @include media-breakpoint-down(sm) {
               padding: 9px 15px 9px 0;
            }

            > ul {
               @include media-breakpoint-down(md) {
                  flex:none;
                  list-style: none !important;
                  padding-left:10px;
               }
            }
         }

         &:before {
            content: '\f105';
            font-family: 'fontawesome';
            margin-right: 5px;
         }

         &.active {
            background-color: $logo1;

            a {
               color: #FFFFFF;
               font-weight: 600;
            }
         }
      }
   }
}

header {
   background-color: white;
   box-shadow: 0 4px 4px rgba(0, 0, 0, .1);
   display: flex;
   justify-content: space-between;
   height: 80px;
   position: fixed !important;
   width: 100%;
   top: 0;
   left: 0;
   z-index: 999;

   @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
      width: 100%;
      height: 50px;
   }

   > .container {
      position: relative;
      /*@include media-breakpoint-down(sm) {
      position: static;
    }*/
   }

   #header_logo {
      flex: none;
      width: 240px;
      height: 80px;
      position: absolute;
      left: 0;
      top: 0;
      overflow-x: hidden;
      @media (max-width: 767px),
      (max-width: 767px) and (-webkit-min-device-pixel-ratio: 1.3) {
         height: 60px;
      }

      .image-logo {
         position: absolute;
         top: 18px;
         left: 15px;
         width: 180px;
         height: 40px;
         @include media-breakpoint-down(sm) {
            width: 120px;
            height: auto;
            left: 1em;
            top: 10px;
            img {
               width: 100%;
               height: auto;
            }
         }
      }
   }

   #main_navigation {
      flex: none;
      width: calc(100% - 240px);
      display: flex;
      height: 80px;
      font-family: 'Roboto', 'Noto Sans KR', sans-serif;
      position: absolute;
      right: 0;
      top: 0;
      justify-content: flex-end;

      @media (max-width: 767px), (max-width: 767px) and (-webkit-min-device-pixel-ratio: 1.3) {
         height: 60px;
      }

      .top-level {
         align-items: center;
         display: flex;
         flex-direction: row;
         justify-content: space-around;
         list-style-type: none;
         flex-basis: calc(100% - 32px);
         padding: 0;
         right: 0;
         margin-bottom: 0;
         position: absolute;
         width: 100%;
         top: 0;
         height: 100%;

         @media (max-width: 993px) {
            display: none;
         }

         .top-child {
            display: block;
            padding: 0;
            position: relative;
            font-weight: 500;
            font-size: 1rem;
            height: 80px;

            &:first-child {
               margin-left: 42px;
            }

            &:last-child {
               margin-right: 42px;
            }

            &.active {
               position: relative;
               display: inline-block;

               &:after {
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  width: 100%;
                  height: 20px;
                  display: block;
                  background-color: $underline;
               }
            }

            a {
               display: inline-block;
               height: 80px;
               line-height: 80px;
               color: #101010;

               &:hover {
                  text-decoration: none;
               }
            }

         }
      }
   }

   #mobile-jubo-action-btn, #mobile-offering-action-btn {
      display: none;
      @media screen and (max-width: 587px) {
         display: inline-block;
         position: absolute;
         top: 12px;
         font-weight: 700;
         font-size: 12px;
         padding: 3px 6px;
      }
   }

   @media screen and (max-width: 640px) {
      #mobile-jubo-action-btn {
         right: 130px;
      }
      #mobile-offering-action-btn {
         right: 60px;
      }
   }

   #allMenuToggle {
      display: block;
      width: 32px;
      height: 32px;
      position: absolute;
      top: 1.5em;
      right: 0;
      background-color: #FFFFFF;
      border: 0;
      padding: 0;

      &:hover {
         cursor: pointer;
      }

      @media (max-width: 767px), (max-width: 767px) and (-webkit-min-device-pixel-ratio: 1.3) {
         top: 1em;
         right: 1em;
         width: 28px;
         height: 20px;
      }

      #nav-icon span {
         display: block;
         position: absolute;
         height: 2px;
         width: 100%;
         background: $menu-dark-grey;

         &:nth-child(1) {
            top: 0;
         }

         &:nth-child(2) {
            top: 25%;
         }

         &:nth-child(3) {
            top: 50%;
         }

         &:nth-child(4) {
            top: 75%;
         }

         &:nth-child(5) {
            top: 100%;
         }
      }
   }
}

.main-sub-menu {
   display: none;
   position: fixed;
   width: 200px;
   background: url('/images/color-chip/light-grey.png');
   top: 80px;
   padding: 1em;
   z-index: 1000;

   .menu-section {
      ul {
         display: flex;
         list-style-type: none;
         padding: 0;
         margin: 0;
         flex-direction: column;
         justify-content: flex-start;

         li {
            padding: 5px;
            border-bottom: 1px solid #A2B5C2;
            font-size: .85rem;
            font-weight: 600;

            &:last-child {
               border-bottom: 0;
            }

            a {
               color: $menu-dark-grey;
               transition: all .3s ease-out;

               &:hover {
                  color: $menu-green;
               }
            }
         }
      }
   }
}

#allSubMenu {
   background-color: $menu-light-grey;
   position: fixed;
   width: 100%;
   left: 0;
   top: 80px;
   z-index: 300;

   @include media-breakpoint-down(md) {
      max-width: 100%;
      overflow-y: auto;
      top: 50px;
      bottom: 80px;
   }

   .container {
      > .menu-section {
         padding: 3em 0 0;
         justify-content: space-between;

         @include media-breakpoint-down(sm) {
            padding-top: 1em;
         }

         ul.col {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin-bottom: 20px;
            list-style-type: none;
            padding-left: 0;

            @include media-breakpoint-down(md) {
               flex-basis: calc(25% - 10px);
               padding-left: 5px;
               padding-right: 5px;
            }

            @include media-breakpoint-down(sm) {
               flex-basis: calc(50% - 10px);
               padding-left: 5px;
               padding-right: 5px;
            }

            > li {
               flex-basis: initial;
               border-bottom: 0;
               font-size: 16px;
               font-weight: 400;
               padding: 2px 0;

               @include media-breakpoint-down(sm) {
                  font-size: 90%;
               }

               &:first-child {
                  color: $menu-green;
                  font-size: 18px;
                  font-weight: 600;
                  border-bottom: 1px solid $menu-blue-grey;
                  margin-bottom: 10px;
               }

               a {
                  @include text-hover-animation($underline);

                  &:hover {
                     color: $menu-green;
                  }
               }

               > ul {
                  list-style:none;
                  padding-left: 10px;
                  font-size: 95%;
                  li {
                     line-height: 1.1;
                     word-break: keep-all;
                     padding: 8px 0;
                  }
               }
            }
         }
      }
   }
}

.front-section-title {
   font-size: 34px;
   font-weight: 700;
   color: $menu-dark-grey;
   @media (max-width: 480px) {
      font-size: 26px;
   }

   small {
      font-size: 20px;
      color: $menu-green;
      @media (max-width: 480px) {
         font-size: 16px;
      }
   }
}

#go-top {
   position: fixed;
   right: 20px;
   bottom: 20px;
   background-color: $menu-light-grey;
   padding: 10px;

   &:hover {
      cursor: pointer;
   }
}

.more {
   display: block;
   padding: 15px 20px;
   border-radius: 40px;
   border: 1px solid $menu-light-grey;
   font-size: 20px;
   line-height: 20px;
   text-align: center;
   width: 185px;
   margin: 0 auto 40px;
   transition: all .6s ease;

   a {
      color: $menu-blue-grey;
   }

   &:hover {
      background-color: $menu-green;
      border-color: $menu-green;

      a {
         color: #FFFFFF;
      }
   }
}

.more-group {
   display: block;
   line-height: 20px;
   position: relative;
   max-width: 320px;
   width: 100%;
   margin: 0 auto;

   &:after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      clear: both;
   }

   .more {
      @extend .more;
      width: 50%;
      position: absolute;
      margin: 0;

      &:first-child {
         border-radius: 40px 0 0 40px;
         left: 0;
      }

      &:last-child {
         border-radius: 0 40px 40px 0;
         border-left: 0;
         right: 0;
      }
   }
}

.blog-title {
   background: url("/images/color-chip/light-grey.png");
   padding: 50px 0 45px;
   @media (max-width: 414px) {
      padding: 1em;
   }

   p {
      margin: 0
   }

   .section-title {
      margin: 0 0 20px 0;
      font-size: 32px;
      text-align: center;
      color: #43484E;
      @media (max-width: 414px) {
         font-size: 22px;
      }
   }
}

#blog-content {
   font: 18px/28px "Iropke Batang", serif;
}

article#blog {
   width: 100%;
   max-width: 1100px;
   margin-left: auto;
   margin-right: auto;
   padding-bottom: 32px;

   @media screen and (max-width: 1099px) {
      padding: 1em;
   }

   h1.title {
      font-size: 2em;
      font-weight: 700;
   }

   @media (max-width: 768px) {
      margin-bottom: 40px;
      margin-top: -40px;
      h1.title {
         font-size: 26px;
      }
   }

   .entry-wrap {
      display: flex;

      .entry-content {
         font: 18px/28px "Iropke Batang", serif;
         max-width: 800px;
         margin-left: auto;
         margin-right: auto;
         width: 100%;
         word-break: break-all;
         word-wrap: break-word;
         white-space: normal;

         p {
            line-height: 1.8em;

            img {
               max-width: 100%;
               width: 100%;
               height: auto;
               margin-bottom: 1em;
            }
         }

         :not(.embed-responsive) iframe {
            width: 100%;
            border: 0;
         }
      }

      .entry-aside {
         display: none;
         flex: none;
         width: calc(100% - 800px);
         padding-left: 30px;

         .blog-aside-title {
            border-bottom: 2px solid $menu-green;
            color: $menu-dark-grey;
            font-weight: 600;
            padding-bottom: 1em;
            margin-bottom: 1em;
         }

         ul {
            padding-left: 28px;

            li {
               color: $menu-blue-grey;
            }
         }
      }
   }
}

.header-slide {
   position: relative;
   margin-top: 80px;
   width:100%;
   @media (max-width: 768px) {
      margin-top: 50px;
   }
}

#main-slider {
   height: 31vw;
   max-height: 800px;
   overflow-y: hidden;
   margin-bottom: 0;
   @include media-breakpoint-down(sm) {
      height: initial;
   }

   .carousel-indicators li {
      width:10px !important;
      height:10px !important;
   }

   .front-slide-item {
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
      @include media-breakpoint-up(sm) {
         &.desk {
            display:block;
         }
         &.mobile {
            display:none;
         }
      }
      @include media-breakpoint-down(sm) {
         &.desk { display:none; }
         &.mobile { display:block; }
      }
   }

}

#front-row-banner {
   background-color: $grey-bg;
   padding: 0;

   .container {
      @include media-breakpoint-down(sm) {
         padding: 0;
         margin: 0;
         width: 100%;
         max-width: 100%;
      }
   }

   ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 1140px;

      @include media-breakpoint-down(sm) {
         flex-wrap: wrap;
      }

      li {
         position: relative;
         flex-basis: 25%;
         z-index: 10;
         @include media-breakpoint-down(sm) {
            flex-basis: 50%;
            height: 75px;
         }

         &:before {
            width: 0;
            height: 4px;
            position: absolute;
            display: block;
            left: 0;
            top: 0;
            content: '';
            background-color: $logo1;
            transition: width .35s ease-out;
            opacity: .3;
            z-index: 20;
         }

         &:after {
            display: block;
            content: url('/images/front/mid-banner/divide.svg');
            height: 44px;
            flex: none;
            width: 5px;
            margin: 0 auto;
            position: absolute;
            right: 0;
            top: 15px;
            @include media-breakpoint-down(sm) {
               display: none;
            }
         }

         &:last-child {
            &:after {
               display: none;
            }
         }

         &:hover {
            cursor: pointer;

            &:before {
               width: 100%;
            }
         }

         figure {
            display: block;
            width: 100%;
            height: 100%;
            position: relative;
            text-align: center;
            padding-left: 10px;
            padding-right: 10px;
            padding-bottom: 10px;

            figcaption {
               margin-top: -20px;
               @include media-breakpoint-down(sm) {
                  margin-top: -13px;
                  font-size: 0.85em;
                  color: seagreen;
               }
            }

            &:hover {
               figcaption {
                  color: $logo3;
               }
            }

            @include media-breakpoint-down(sm) {
               padding: 0;
               border: 1px solid rgba(0, 0, 0, .05);
            }

            .img-wrap {
               height: 80px;
               width: 100%;
               text-align: center;
               display: flex;
               flex-direction: column;
               justify-content: center;
               @include media-breakpoint-down(sm) {
                  height: 60px;
               }

               img {
                  width: 250px;
                  margin-left: auto;
                  margin-right: auto;
                  @include media-breakpoint-down(md) {
                     width: 150px;
                  }
                  @include media-breakpoint-up(md) {
                     width: 180px;
                  }
               }
            }
         }
      }
   }
}

#event-section {
   background-color: rgba(97, 166, 167, 0.7);
   @include media-breakpoint-down(sm) {
      padding: 20px 15px;
   }

   > .rev_slider_wrapper {
      height: 90px !important;
      @include media-breakpoint-down(sm) {
         height: 100px !important;
      }
   }
}

#event-slider {
   width: 100%;
   padding: 20px 0 20px;

   a {
      color: white;
   }

   h5.event-title {
      color: #053828;
      font-size: 24px;
      font-weight: 700;
   }
}

.empty-event-word {
   word-break: keep-all;
}

.single-sermon-item {
   .card-content {
      min-height: 180px;

      h4 {
         font-size: 22px !important;
      }

      .card-excerpt {
         color: $light-dark;
      }
   }
}

.date-speaker {
   color: $brown-color;
}

// 캐러젤 컨트롤 배경 제거
.carousel-control.right, .carousel-control.left {
   background-image: none;
}

#program-latest > .col-sm-4 > .card-sm:last-child {
   border-bottom: none;
}

.gap {
   display: table;
   width: 100%;
   height: 32px;
   clear: both;
}

// 시리즈 설교 배너 (검색 부분)
#series-sermon-banner {
   height: 350px;
   padding: 40px;
   margin-bottom: 40px;

   h1.title {
      margin: 30px 0 20px;
      text-align: center;
      color: $dark;
      font-weight: 700;
      font-size: 42px;

      small {
         display: block;
         padding: 5px;
      }
   }
}

#search-wrap {
   input[type='text'] {
      border-radius: 30px 0 0 30px;
      margin-right: -5px;
      font: 300 16px/16px 'Roboto', 'Noto Sans KR', sans-serif;
      width: 400px;
      text-align: center;
      @media screen and (max-width: 414px) {
         width: 250px;
      }
   }

   button.btn {
      border-radius: 0 30px 30px 0;
      min-width: 70px;
   }
}

#sort {
   margin-top: 20px;

   a {
      color: $dark;
      margin-right: 1em;

      &:hover {
         text-decoration: none;
         color: $orange;
      }
   }
}

#video-list {
   padding: 1em 0;

   .table {
      td {
         font-size: 14px;
      }
   }
}

#video-control {
   span {
      display: inline-block;
      margin-right: 20px;
      height: 42px;
   }
}

.row-divide {
   content: url(/images/row-divide.png);
   margin: 60px auto;
}

// 설교 보기 페이지
.single-sermon-header {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   height: 350px;

   h1.title {
      color: #FFFFFF;
      display: block;
      text-shadow: 0 0 4px rgba(0, 0, 0, .4);
      text-align: center;
      font-size: 48px;
      clear: both;
   }

   .category {
      color: #FFFFFF;
      display: block;
      padding: .2em 2em;
      font-size: 20px;
      background-color: $sand-color;
      border-radius: 20px;
      margin: 10px;
   }
}

.single-sermon-info {
   list-style-type: none;
   padding-left: 0;
   padding-bottom: 1em;
   margin-bottom: 2em;
   text-align: left;

   li {
      color: $dark;
      display: inline-block;
      font-family: 'Iropke Batang', serif;
      font-size: 18px;
      margin-right: 2em;

      .item-title {
         color: $logo1;
         font: 500 16px/1.8em 'Noto Sans KR', sans-serif;
         display: inline-block;
         margin-right: .5em;

         &::before {
            color: $logo2;
            content: '\f14a';
            font-family: FontAwesome;
            margin-right: 5px;
         }
      }
   }
}

article.single-sermon-content {
   flex-basis: 800px;
   font: 18px/1.8em 'Iropke Batang', serif;

   .btn {
      font-family: 'Noto Sans KR', sans-serif;
   }

   blockquote {
      border-left-color: $logo1;
      color: $dark;
      font-size: 20px;
      font-family: 'Roboto', 'Noto Sans KR', sans-serif;
      margin-top: 1em;
      margin-bottom: 1em;
   }
}

.keyword-wrap {
   display: flex;
   justify-content: flex-start;
   flex-wrap: wrap;
   margin-bottom: 20px;
   font-family: 'Roboto', 'Noto Sans KR', sans-serif;
}

.keyword-title {
   color: $logo6;
   padding: 0;
   height: 32px;
   line-height: 30px;
   font-weight: 400;
   font-size: 16px;
   border: 2px solid $logo6;
   text-align: center;
   width: 100px;
}

.keyword {
   list-style-type: none;
   padding-left: 20px;
   height: 32px;
   line-height: 32px;
   margin: 0;
   display: flex;
   justify-content: flex-start;

   li {
      font-size: 14px;

      a {
         color: $default-color;

         &:hover {
            text-decoration: none;
            color: $dark;
         }
      }

      &:after {
         content: ',';
         margin-right: 5px;
      }

      &:last-child {
         &:after {
            content: none;
         }
      }
   }
}

.relate-title {
   background-color: $sand-color;
   border-radius: 20px;
   color: #FFFFFF;
   display: inline-block;
   font-weight: 600;
   padding: 8px 20px;
   margin-bottom: 20px;
}

nav.profile-menu {
   ul {
      display: flex;
      list-style-type: none;
      padding-left: 0;
      margin: 30px 0;

      li {
         a {
            color: $dark;
            font-weight: 600;

            &:hover {
               color: $logo1;
            }
         }

         &:after {
            content: '';
            margin: 0 10px;
            border-right: 1px solid #CCCCCC;
         }

         &:last-child {
            &:after {
               content: none;
               border-right: none;
            }
         }
      }
   }
}

section.lastest-list {
   a {
      p {
         color: $dark;
         @include text-hover-animation($underline);
      }
   }
}

// 스텝소개 페이지

.profile-card {
   margin-bottom: 20px;
   padding-bottom: 1em;
   position: relative;

   .profile-thumbnail, .pastor-thumbnail {
      position: relative;
      width: 100%;
      height: 200px;
      background-size: cover;
      margin-bottom: 1em;
      transition: all .4s ease-out;

      @include media-breakpoint-down(sm) {
         background-position-y: 20%;
         height: 200px;
      }

      &:hover {
         cursor: pointer;
         background-position: 50% 30%;
         @include media-breakpoint-down(sm) {
            background-position-y: 20%;
         }
      }
   }

   img {
      width: 100%;
      margin-bottom: 10px;
   }

   ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      text-align: center;
      height: 110px;

      .name {
         font-weight: 600;
      }

      .job {
         font-weight: 300;
         font-size: 14px;
      }
   }
}

// 개인 프로필
.profile {
   h3.name {
      color: darken($menu-blue-grey, 20%);
      font-size: 32px;
      margin-bottom: 20px;
      line-height:1;

      small {
         font-size: 16px;
      }

      @media (max-width: 480px) {
         text-align: center;
         small {
            display: block;
         }
      }
   }

   p {
      font: 16px/1.8em 'Iropke Batang', serif;

      strong {
         color: $orange;
         font-family: initial;
      }
   }

   h4 {
      color: $dark;
      font-weight: bold;
   }

   .order-wrap {
      display: flex;
      flex-direction: row;
      padding-right: 32px;
      margin-bottom: 32px;
      @media (max-width: 414px) {
         flex-wrap: wrap;
         padding-right: initial;
      }

      .mobile-order-1 {
         flex: none;
         width: 300px;
         @media (max-width: 480px) {
            order: 1;
            width: 100%;

         }

         .senior-pastor-photo {
            flex-basis: 200px;
            margin-left: 40px;
            margin-bottom: 32px;
            padding: 10px;
            border: 1px solid #CCCCCC;
            @media (max-width: 480px) {
               margin-left: auto;
               margin-right: auto;
               width: 60%;
            }
         }
      }

      .mobile-order-2 {
         blockquote {
            p {
               b {
                  color: $logo1;
               }
            }
         }

         @media (max-width: 414px) {
            order: 2;
         }
      }
   }

   .senior-pastor-signature {
      @media (max-width: 480px) {
         width: 60%;
         height: auto;
      }
   }

   .greeting {
      font: 18px/1.8em "Iropke Batang", serif;

      h4 {
         font-family: Roboto, 'Noto Sans KR', sans-serif;
         font-size: 24px;
         font-weight: 200;
         color: $menu-blue-grey;
      }

      .personal-history {
         p {
            display: block;
            margin-left: 64px;
            text-indent: -32px;
            clear: right;

            span.year {
               display: inline-block;
               color: $menu-blue-grey;
               margin-right: 10px;
               font-family: Roboto, 'Noto Sans KR', sans-serif;
            }
         }
      }
   }

   .job-description {
      p {
         &:before {
            content: '\f0b1';
            display: inline-block;
            margin-right: 10px;
            font-family: 'fontawesome';
         }
      }
   }

   .contact {
      margin-top: 20px;

      p {
         &:before {
            content: '\f0b1';
            display: inline-block;
            margin-right: 10px;
            font-family: 'fontawesome';
         }
      }

      ul {
         list-style-type: none;
         padding: 0;

         li.tel > span, li.email > span {
            font-weight: 600;
            display: inline-block;
            width: 80px;
            color: $sand-color;
         }

         .sns {
            margin-top: 20px;

            a {
               display: inline-block;
               width: 32px;
               height: 32px;
               margin-right: 7px;

               img {
                  width: 100%;
               }
            }
         }
      }
   }
}

// 추수감사절 레이아웃
#autumn-bg-layer {
   background-image: url(/images/front-autumn-bg.jpg);
   background-size: 100%;
   background-repeat: repeat-y;
   padding-bottom: 60px;

   &.bg-expand {
      background-size: 120%;
   }

   &.bg-position-right {
      background-position: right;
   }

   &.bg-position-left {
      background-position: left;
   }

   &.bg-position-center {
      background-position: center;
   }
}

// 푸터
footer {
   background-color: $dark;
   color: #FFFFFF;
   clear: both;
   padding: 2em 0;
   display: flex;
   @include media-breakpoint-down(sm) {
      margin-top: 0;
   }

   .logo-wrap {
      display: flex;
      align-items:flex-start;
      @include media-breakpoint-down(sm) {
         flex-direction: column;
         align-items:center;
      }
   }

   .logo-section {
      flex-basis: 150px;
      color: #707980;
      font-size: 32px;
      font-weight: bold;
      @include media-breakpoint-down(sm) {
         flex-basis: auto;
         text-align: center;
         small {
            text-align: center;
         }
      }

      small {
         font-size: 14px;
         font-weight: normal;
         display: block;
      }
   }

   .footer-nav {
      flex-basis:calc(100% - 200px);
      display: flex;
      flex-wrap: wrap;
      font-size: .8em;
      padding-left: 2rem;
      @include media-breakpoint-down(sm) {
         padding-left:0;
      }
      .nav-link {
         padding: .3rem .5rem;
      }
   }

   .bottom-bar {
      margin-top: 20px;
      border-top: 1px solid $light-dark;
      padding: 1em 0 .5em;
      font-size: 12px;

      span {
         margin-right: 30px;
         color: $default-color;

         b {
            color: #FFFFFF;
         }

         @media (max-width: 736px) {
            margin-right: 20px;
         }
      }

      .sns-link {
         list-style-type: none;
         padding: 0;
         margin: 0;
         display: inline-block;

         li {
            display: inline-block;
            margin-right: 20px;
         }
      }
   }
}

/*오브젝트 템플릿*/
.lb-data .lb-cation {
   font-size: 140% !important;
}

/* 플로팅 검색창 */
#search-section {
   display: none;
   position: fixed;
   top: 50%;
   right: 0;
   z-index: 100;
   width: 300px;

   .form-group {
      position: relative;
   }

   .search-input {
      width: 300px;
      height: 48px;
      font-size: 24px;
      border-radius: 30px 0 0 30px;
      border: 3px solid $menu-blue-grey;
      position: absolute;
      right: 0;
   }

   button {
      background: transparent;
      color: $menu-dark-grey;
      border: none;
   }

   button[type="submit"] {
      position: absolute;
      top: 10px;
      right: 10px;
   }
}

#search-input-trigger {
   background: $menu-light-grey;
   color: $menu-dark-grey;
   border: none;
   border-radius: 10px 0 0 0;
   position: fixed;
   font-size: 28px;
   width: 50px;
   height: 50px;
   bottom: 0;
   right: 0;
   z-index: 99;

   span {
      position: absolute;
      top: 10px;
      left: 10px;
   }
}

/*카드디자인*/

.card {
   position: relative;
   margin-bottom: 40px;
   border: 1px solid #d3dbee;
   font-size: 14px;

   &.rounded {
      border-radius: 16px;
      overflow: hidden;
   }


   a {
      @include text-hover-animation($underline);
      color: $menu-dark-grey;
   }

   .thumbnail-img {
      background-size: cover;
      background-position: center;
      transition: all .4s ease;

      &:hover {
         background-position-y: 30%;
         cursor: pointer;
         box-shadow: 0 0 8px rgba(0, 0, 0, .2);
      }
   }

   .card-title {
      background-color: #fbfbfb;
      padding: 10px;
      height: 130px;
      margin-bottom: 0;
      @media (max-width: 736px) {
         height: initial;
      }

      .card-category-name {
         position: absolute;
         left: 0;
         top: 10px;
         padding: 4px 12px 4px 8px;
         border-radius: 0 20px 20px 0;
         font-size: 13px;
         background-color: $menu-dark-grey;
         color: $underline;

         .date {
            color: #FFFFFF;
         }
      }

      h4 {
         font-size: 16px;
         font-weight: 700;
         line-height: 1.5em;
         @media(max-width: 1080px) {
            font-size: 16px;
         }
      }

      p {
         @media(max-width: 1080px) {
            font-size: 14px;
         }
      }
   }

   &.series-sermon-card {
      .card-title {
         height: 100px;
      }
   }
}

/* 갤러리 */
#gallery-layout {
   max-width: 1280px;
   margin: 0 auto;
   box-shadow: rgba(black, .1) 0 4px 10px;
}

.gallery-header {
   top: 0;
   left: 0;
   width: 100%;
   box-shadow: none;
   background-color: white;

   .nav-wrapper {
      width:100%;
      max-width:1280px;
      margin-left:auto;
      margin-right:auto;
      padding:0 1em;
      box-shadow: rgba(black, .1) 0 4px 10px;
   }

   .image-logo {
      flex-basis: 180px;
      max-width:180px;
      @include media-breakpoint-down(md) {
         max-width:180px;
      }
      a > img {
         width:100%;
      }
   }

   #gallery-nav {
      z-index: 999;
      flex-basis: calc(100% - 220px);

      @include media-breakpoint-down(md) {
         display:none;
      }

      ul {
         list-style-type: none;
         display: flex;
         flex: 1;
         justify-content: flex-end;
         margin: 0;
         padding: 0;
         height: 80px;
         align-items: center;

         .top-child {
            margin-right: 30px;

            &:last-child {
               margin-right: 0;
            }

            a {
               @include text-hover-animation($underline);
               color: $menu-dark-grey;
               font-weight: 500;
               font-size: 1.2rem;
               letter-spacing: -.03em;
            }
         }

      }
   }
}

#gallery-mobile-nav-trigger {
   @include media-breakpoint-up(md) {
      display:none;
   }

   background: none;
   border: none;
   position: absolute;
   right: 4%;
   top: 12px;
   z-index: 999;
   width: 32px;
   height: 32px;
   text-align: right;
   color: #9064CC;
   font-size: 32px;
   padding: 0;
   overflow: hidden;

   > span {
      display: block;
      width: 100%;
      height: 3px;
      background: $menu-dark-grey;
      margin-bottom: 5px;
   }
}

#gallery-nav-mobile {
   background-color: $menu-light-grey;
   position: fixed;
   right: -200px;
   height: calc(100vh - 80px);
   width: 200px;
   font-size: 18px;
   padding: 15px;
   top: 80px;
   transition: all .3s ease-in;
   z-index: 999;

   a {
      color: $menu-dark-grey;
   }

   .top-level {
      list-style-type: none;
      padding-left: 0;
      margin-bottom: 0;

      li {
         padding: 5px 0;

         a {
            color: $menu-dark-grey;
         }
      }
   }
}

#gallery-slide-section {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin-top: 80px;
   height: 150px;
   position: relative;
   text-align: center;
   margin-bottom:40px;

   .page-title {
      margin: 0;
      color: #FFFFFF;
   }
}

#gallery-main {
   @include media-breakpoint-down(md) {
      padding-top:50px;
   }
}

#exhibition-now {
   padding: 40px 0;
   @media (max-width: 768px) {
      padding: 15px 0;
   }

   h2 {
      margin-bottom: 20px;
      @media (max-width: 768px) {
         padding: 0 15px;
         small {
            display: block;
         }
      }
   }

   .row {
      margin-left: 0;
      margin-right: 0;
   }

   .title-part {
      @media (max-width: 768px) {
         padding-left: 15px;
         padding-right: 15px;
         padding-top: 30px;
      }

      .artist {
         font-size: 1.5em;
         color: $sand-color;
         padding: 0 15px;
         @media (max-width: 768px) {
            padding: 0;
         }
      }

      .subject {
         font-size: 2.5em;
         font-weight: 700;
         color: $orange;
         padding: 0 15px;
         line-height: 1em;
         margin-bottom: 1em;
      }

      .exhibition-term {
         margin-bottom: 2em;
         color: $dark;
         @media (max-width: 768px) {
            span.from, span.to {
               display: inline-block;
               width: 100%;

               b {
                  margin-left: 0;
               }
            }
         }

         b {
            color: $light-dark;

            &:last-child {
               margin-left: 1em;
            }
         }
      }

      .exhibition-content {
         padding: 0 15px;
      }

      .thumbnail {
         padding: 5px;
         border: 1px solid $menu-light-grey;
         border-radius: 0;
         background-color: #FFFFFF;

         img {
            transition: scale .3s ease;

            &:hover {
               transform: scale(1.2);
            }
         }
      }

      figure {
         display: flex;
         justify-content: space-between;

         img {
            margin-right: 1em;
         }

         figcaption {
            font-weight: 300;
            letter-spacing: -.03em;

            .profile {
               display: block;
               margin-top: .5em;
               color: $dark;
               font-weight: 500;

               &:hover {
                  color: $orange;
               }
            }
         }
      }
   }
}

figure.presentation {
   img {
      width: 100%;
      height: auto;
   }

   figcaption {
      padding-top: 10px;
      font-size: 80%;
   }
}

.mobile-banner {
   display: none;
   @media (max-width: 768px) {
      display: block;
      width: 100%;
   }
}

#exhibition-ad {
   display: flex;
   background-color: #F08223;
   color: white;
   padding: 60px 40px;
   max-width: 1280px;
   @include media-breakpoint-down(sm) {
      display: block;
   }

   .info {
      flex-basis: 80%;
      letter-spacing: -0.02em;
      @media (max-width: 440px) {
         width: 100%;
         margin-bottom: 20px;
      }

      .caption-block {
         padding: 0;

         .title {
            font-size: 2em;
            line-height: 1.3;
            margin-bottom: .8em;
            letter-spacing: -0.02em;
            font-weight:700;
            @include media-breakpoint-down(sm) {
               font-size:1.4em;
            }
         }

         .lead {
            padding-right: 20px;
         }
      }

      #exhibition-apply {
         border: 4px solid #FFFFFF;
         padding: .2em 1em;
         font-size: 28px;
         font-weight: lighter;
         max-width: 300px;
         background-color: #F08223;
         transition: background-color .4s ease-out;

         &:hover {
            font-weight: bold;
            background-color: #FFFFFF;
            color: #F08223;
         }

         @media (max-width: 768px),
         (max-width: 768px) and (-webkit-min-device-pixel-ratio: 1.3) {
            width: 100%;
            font-size: 18px;
         }
      }
   }

   .latest-exhibition, .latest-exhibiton {
      flex: none;
      width: 20%;
      @media (max-width: 440px) {
         width: 100%;
      }
   }

}

#gallery-library {
   h2 {
      margin-bottom: 20px;
   }

   figure {
      margin-bottom: 30px;
      transition: all .4s ease-in-out;

      img {
         width: 100%;
         height: auto;
         margin-bottom: 20px;
      }

      figcaption {
         min-height: 142px;

         .gallery-excerpt {
            margin-bottom: 10px;
            text-align: center;

            a {
               @include text-hover-animation($underline);
            }

            .exhibtion-title {
               margin-top: 20px;
               margin-bottom: 20px;
               color: #F08223;
            }

            .artist {

            }

            &:after {
               display: block;
               content: '';
               width: 50px;
               height: 4px;
               background-color: #F08223;
               margin-top: 8px;
               margin-left: auto;
               margin-right: auto;
            }
         }

         .date {
            font-size: 85%;
            font-weight: lighter;
            text-align: center;
         }
      }
   }
}

.gallery-thumbnail {
   padding: 10px;
   border-radius: 0;
   background-color: #FFFFFF;
   border: 1px solid $menu-light-grey;

   img {
      transition: scale .8s ease-in-out;

      &:hover {
         transform: scale(1.15);
         transform-origin: center;
      }
   }

   .fake-img {
      width: 100%;
      height: 225px;
      background-color: rgba(128, 89, 98, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
   }
}

.gallery-footer {
   margin-left: auto;
   margin-right: auto;
   max-width: 1280px;

   .logo {
      flex: none;
      width: 150px;
      margin-right: 60px;

      img {
         width: 100%;
         margin-bottom: 20px;
      }
   }

   .information {
      display: flex;
      justify-content: flex-start;

      dl {
         flex-basis: 1;
         padding: 0 20px;

         dt {
            margin-bottom: 10px;
         }

         dd {
            font-weight: 300;
            font-size: 90%;
            word-break:keep-all;
         }
      }
   }

   .copyright {
      flex: none;
      width: 100%;
      display: block;
      height: 25px;
      background-color: transparent;
      line-height: 25px;
      font-size: 12px;
      font-weight: lighter;
   }
}

/****  CONTENTS STYLES ****/
.impact {
   color: $logo1;
}

// 페이지헤더배경
.bg-wrap {
   width: 100%;
   height: 100%;
   position: absolute;

   > img {
      @media (max-width: 414px) {
         width: 100%;
      }
      @media (min-width: 415px) and (max-width: 768px) {
         width: 100%;
         margin-left: auto;
         margin-right: auto;
         bottom: 0;
      }
   }
}

#staff {
   .nav.nav-tabs {
      .nav-item {
         .nav-link {
            &.active {
               background-color: rgba(95, 174, 144, 0.88);
               color: white;
            }
         }
      }
   }

   .sns {
      padding-top: 8px;

      .icon {
         display: inline-block;
         width: 24px;
         height: 24px;
         border-radius: 50%;
         overflow: hidden;

         img {
            width: 100%;
            height: 100%;
         }
      }
   }
}

.bg-staff {
   background: url('/images/staff/bg-top.jpg') repeat;

   img {
      width: initial !important;
      height: auto;
   }
}

.bg-sermon {
   @extend .bg-staff;
   background-image: url(/images/subpage-header/sermon.jpg);
   background-repeat: no-repeat;
   background-position: 50% 50%;
   background-size: cover;
}

.bg-ministry {
   //background-image: url('/images/subpage-header/mission-2.jpg');
}

.bg-blog {
   background-attachment: fixed;
   background-size: cover !important;
   background-position: 50% 100% !important;
}

#gtku {
   .welcome-profile {
      @media (min-width: 769px) {
         float: left;
         margin: 0 30px 30px 0;
         width: 300px;
         height: auto;
      }
      @media (min-width: 736px) and (max-width: 768px) {
         float: left;
         margin: 0 30px 30px 0;
         width: 250px;
      }
      @media (max-width: 735px) {
         width: 100%;
         clear: both;
         margin: 0 auto 30px;
      }
   }

   p {
      font-size: 18px;
      font-family: 'Iropke Batang', serif;
      line-height: 1.8em;
   }

   .registration {
      list-style-type: none;
      padding-left: 0;

      li {
         font-size: 18px;
         margin-bottom: 20px;
         color: $logo1;

         span.no {
            background-color: $logo1;
            color: #FFFFFF;
            border-radius: 50%;
            display: inline-block;
            width: 32px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            font-size: 16px;
            margin-right: 10px;
         }

         p {
            font-size: 16px;
            padding-left: 48px;
            color: $text-color;
         }
      }
   }

   .service-table {
      border-top: 2px solid $logo4;

      thead td {
         background-color: $light-grey;
         color: $dark;
      }
   }

   .card.service-time {
      .card-title {
         height: initial;
         font-weight: 500;
         color: $logo1;
      }

      .card-body {
         background-color: $light-grey;
         padding: 10px;
      }
   }

   .bus-info {
      .bus {
         padding-left: 1.2em;
      }

      h5.bus-blue {
         color: #1D78CB;
         font-size: 16px;
      }

      h5.bus-green {
         color: $logo1;
         font-size: 16px;
      }

      .bus {
         .no {
            margin-right: 1em;
         }
      }

      .bus-blue {
         span.no {
            color: #1D78CB;
            font-weight: 600;
         }
      }

      .bus-green {
         span.no {
            color: $logo1;
            font-weight: 600;
         }
      }
   }

   .course-wrap {
      margin-bottom: 32px;

      .course-group {
         margin-bottom: 1em;

         .course-title {
            color: #FFFFFF;
            background-color: $logo3;
            text-shadow: 0 0 4px rgba(0, 0, 0, .8);
            padding: 10px 20px;
            border: 1px solid $light-grey;
            border-radius: 4px 4px 0 0;
            font-size: 14px;

            h4 {
               color: white !important;
               margin-top: 0;
               margin-bottom: 0;
               font-size: 1.2rem;

               .line-info {
                  color: #F2FD0D !important;
                  padding-left: 10px;
               }
            }

            .driver-info {
               text-align: right;
               @media(max-width: 414px) {
                  text-align: left;
                  padding-top: 5px;
               }
            }

            .memo {
               border-top: 1px solid $logo2;
               padding-top: 5px;
               margin-top: 5px;
            }
         }

         .course {
            display: flex;
            flex-wrap: wrap;
            border: 1px solid $light-grey;
            padding: 1em;

            .stop {
               flex-basis: 135px;
               padding: .5em 1em;
               border: 1px solid $default-color;
               font-size: 14px;
               position: relative;
               margin: 0 30px 10px 0;
               @media(max-width: 414px) {
                  flex-basis: 100%;
                  margin-right: 0;
                  margin-bottom: 0;
                  border: 0;
               }

               &::after {
                  content: '\f061';
                  font-family: 'FontAwesome';
                  position: absolute;
                  right: -24px;
                  top: 33%;
                  color: $logo1;
                  @media (max-width: 414px) {
                     display: none;
                  }
               }

               &:nth-child(5n) {
                  &::after {
                     @media (min-width: 1024px) {
                        content: '';
                     }
                  }
               }

               &:last-child {
                  &::after {
                     content: '';
                  }
               }

               &:nth-child(even) {
                  background-color: #C4DDBF;
               }

               .time {
                  color: $logo1;
                  font-weight: 600;
                  @media (max-width: 414px) {
                     display: inline-block;
                     padding-right: 1em;
                  }
               }

               p {
                  margin-bottom: 0;
                  font-family: 'Noto Sans KR', sans-serif;
                  font-size: 14px;
                  line-height: 1.4em;
                  @media (max-width: 414px) {
                     display: inline-block;
                  }
               }
            }
         }
      }
   }

   .facility {
      padding-left: 0;
      list-style-type: none;
      margin: 20px 0 0 0;

      li {
         display: flex;
         border-bottom: 1px dashed $light-grey;
         margin-left: 0;
         margin-bottom: 10px;
         padding-bottom: 10px;

         & > div {
            flex-basis: 100%;
            display: flex;
            flex-wrap: wrap;

            &:first-child {
               flex: none;
               width: 70px;

               .no {
                  padding: 5px 10px;
                  margin-right: 10px;
                  background-color: $logo1;
                  color: #FFFFFF;
                  font-weight: 600;
                  border-radius: 8px 0 8px 0;
               }
            }

            span:not(.no) {
               display: inline-block;
               min-width: 150px;
               margin-right: 10px;

               &:first-child {
                  margin-left: 0;
               }
            }
         }
      }
   }

   #facility-photo-view {
      padding: 1em;
      margin-top: 30px;
      border: 1px solid $light-grey;
      background: url('/images/welcome/church-bg.jpg') no-repeat;
      background-size: cover;
      background-position: 50% 50%;
      min-height: 500px;
      @media(max-width: 414px) {
         min-height: 200px;
      }
   }

   .fclt {
      padding: 2em 1em;

      .pull-left {
         margin-right: 10px;
         margin-bottom: 10px;

         img.img-responsive {
            width: 120px;
            height: auto;
         }
      }
   }

   .handicapped {
      padding-left: 0;
      list-style-type: none;
      display: flex;
      justify-content: flex-start;
      margin-bottom: 0;
      @media(max-width: 414px) {
         flex-wrap: wrap;
      }

      li {
         margin-right: 1em;

         a {
            color: $text-color;
            position: relative;
            z-index: 99;

            &::before {
               display: inline-block;
               width: 10px;
               height: 4px;
               background-color: #EFFF73;
               transition: width 2s;
               position: absolute;
               left: 0;
               bottom: 0;
               z-index: 100;
            }

            &:hover {
               color: $logo1;

               &::before {
                  width: 100%;
               }
            }
         }

         &::before {
            content: '\f101';
            font-family: 'fontawesome';
            display: inline-block;
            margin-right: 5px;
            color: $logo1;
         }
      }
   }
}

.nextgen-page {
   @media (max-width: 996px) {
      margin-bottom: -40px;
   }
}

#nextgen-sub-menu {
   background-color: $logo1;
   width: 100vw;

   .container {
      @media(max-width: 1024px) {
         padding-left: 0;
         padding-right: 0;
         margin: 0;
         width: 100% !important;
      }
   }

   nav {
      ul {
         padding: 0;
         margin: 0;
         list-style-type: none;
         display: flex;
         @media (max-width: 1024px) {
            width: 100vw;
         }

         li {
            flex-basis: 25%;
            text-align: center;
            font-size: 18px;
            font-weight: 500;
            padding: 20px 0;
            position: relative;
            z-index: 100;

            &::after {
               background-color: rgba(205, 255, 64, .7);
               content: '';
               left: 0;
               bottom: 0;
               z-index: 110;
               position: absolute;
               width: 0;
               height: 100%;
               transition: width .3s;
            }

            &:hover {
               &::after {
                  width: 100%;
               }
            }

            &:nth-child(1) {
               background-color: $orange;
            }

            &:nth-child(2) {
               background-color: #D5A38A;
            }

            &:nth-child(3) {
               background-color: #27B5D4;
            }

            &:nth-child(4) {
               background-color: #1F6551;
            }

            &:nth-child(5) {
               background-color: #173760;
            }

            &:nth-child(6) {
               background-color: #2A135B;
            }

            a {
               color: #FFFFFF;
               position: relative;
               z-index: 120;

               &:hover {
                  color: $dark;
                  font-weight: 500;
               }

               .icon {
                  width: 42px;
                  height: 42px;
                  position: position;
                  left: calc(50% - 30px);
                  top: 0;
               }

               span {
                  padding-left: 10px;
                  @media (max-width: 1024px) {
                     display: inline-block;
                     margin: 10px 0 0 0;
                     padding: 0;
                     width: 100%;
                     font-size: 85%;
                  }
               }
            }
         }
      }
   }
}

.nextgen-bg {
   background-size: initial;
   background-repeat: repeat !important;
   background-image: url(/images/nextgen/pattern.png);
}

.nextgen-main {
   @media (min-width: 1280px) {
      padding: 8em;
      margin-bottom: -60px;
      .container {
         padding: 0 !important;
         margin: 0 auto !important;
         /*width: 100% !important;*/
      }
   }

   @media (max-width: 414px) {
      padding: 1em;
      margin-bottom: 0;
      h1 {
         margin-bottom: 10px;
      }
      img {
         margin-bottom: 10px;
      }
      .label {
         display: inline-block;
         margin-bottom: 5px;
      }
      br {
         display: none;
      }
      h4 {
         margin-top: 20px;
      }
   }
   @media (min-width: 415px) and (max-width: 1279px) {
      padding: 2em;
      margin-bottom: 0;
      .container {
         padding: 0 !important;
         margin: 0 !important;
         width: 100% !important;
      }
      h1 {
         margin-bottom: 10px;
      }
      img {
         margin-bottom: 10px;
      }
      .label {
         display: inline-block;
         margin-bottom: 5px;
      }
      br {
         display: none;
      }
      h4 {
         margin-top: 20px;
      }
   }

   span.label {
      font-size: 16px;
      border-radius: 20px;
   }
}

#children-church {
   background-color: #F9C310;
   background-image: url(/images/nextgen/bg3-1.png);
   color: $dark;
}

#youth-church {
   background-color: #145929;
   background-image: url(/images/nextgen/bg3-2.png);
   color: #FFFFFF;
}

#youngman-church {
   background-color: #143359;
   background-image: url(/images/nextgen/bg3-3.png);
   color: $light-grey;
}

#nextgen {
   @media(max-width: 414px) {
      padding-bottom: 40px;
   }

   .image-list-carousel {
      .col-sm-4 {
         height: 300px;
         background-size: cover !important;
         background-position-y: 50% !important;
      }
   }
}

#photoviewer {
   display: none;
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, .8);
   z-index: 1000;

   a {
      position: absolute;
      bottom: 50px;
      left: 50%;

      span {
         color: #FFFFFF;
         font-size: 32px;
      }
   }

   img {
      position: absolute;
      left: 50%;
      top: 50%;
   }
}

#sermon-show {
   .entry-content {
      word-break: break-all;
      font-family: 'Iropke Batang', serif;
      line-height: 1.8em;
      font-size: 18px;

      p, div {
         font-family: 'Iropke Batang', serif;

         strong {
            font-family: 'Roboto', 'Noto Sans KR', sans-serif;
         }
      }
   }

   .sub-data {
      span {
         &::after {
            content: '/';
            padding: 0 10px;
         }

         &:last-child {
            &::after {
               content: '';
               padding: 0;
            }
         }
      }
   }

}

.vjs-big-play-button {
   position: absolute;
   left: 46% !important;
   top: 44% !important;
}

#episode {
   h2.page-title {
      display: inline-block;
      border-bottom: 3px solid #3EF03E;
      padding-bottom: 5px;
      margin: 0 auto 10px;

   }

   .episode-list {
      .thumbnail {
         width: 80px;
         padding: 0;
         border: 0;
         border-radius: 2px;
         margin: 0;
      }

      .glyphicon-play {
         color: $logo1;
      }

      .excerpt {
         font-family: "Iropke Batang", serif;
         font-size: 13px;
         margin-bottom: 0;
      }

      td, th {
         vertical-align: middle;

         a {
            color: $text-color;

            &:hover {
               color: $logo1;
            }
         }
      }
   }
}

.input-round {
   padding: 10px;
   border-radius: 32px;
   border: 1px solid #CCCCCC;
}

#sermon-search, #blog-search {
   margin-bottom: 32px;

   .form-group {
      position: relative;
   }

   .input-round {
      width: 100%;
      text-align: center;

      &:focus {
         outline: none;
      }
   }

   .inner-button {
      position: absolute;
      border: 0;
      background-color: transparent;
      right: 20px;
      top: 8px;

      span {
         font-size: 20px;
      }
   }
}

.history-list-menu {
   list-style-type: none;
   padding-left: 0;
   margin-bottom: 0;

   li {
      display: inline-block;

      &:after {
         content: '';
         margin: 0 10px;
         border-right: 1px solid #CCCCCC;
      }

      &:last-child {
         &:after {
            display: none;
         }
      }

      a {
         @include text-hover-animation($underline);
      }
   }
}

.history-list {
   .history-section-title {
      display: block;
      color: $logo1;
      padding-bottom: 10px;
      margin: 40px 10px 10px;
      border-bottom: 1px solid $menu-blue-grey;
      font-size: 180%;
      font-weight: 900;
   }

   dl {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 10px;
      padding: 5px 8px;

      &:nth-child(even) {
         background-color: #EEF8F1;
      }

      dt {
         color: $logo1;
         font-weight: 400;
         flex: none;
         width: 120px;
         margin-right: 30px;
      }

      dd {
         font-family: 'Iropke Batang', serif;
      }
   }
}

#vision {
   p {
      font-family: 'Iropke Batang', serif;
      font-size: 18px;
      line-height: 1.7em;
   }

   .collection-group {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      margin-bottom: 32px;
      @media (max-width: 414px) {
         flex-wrap: wrap;
      }

      &:last-child {
         .collection {
            flex-basis: calc(50% - 30px);
            @media (max-width: 414px) {
               flex-basis: 100%;
            }
         }
      }

      .collection {
         flex-basis: 50%;
         @media (max-width: 414px) {
            flex-basis: 100%;
         }

         .collection-title {
            font-weight: 600;
            font-size: 120%;
            color: $menu-dark-grey;
            border-bottom: 2px solid $menu-light-grey;
            margin-bottom: 1em;
            padding: 10px;

            .no {
               color: $menu-blue-grey;
               font-weight: 900;
               font-size: 180%;
               margin-right: 10px;
            }
         }

         &:first-child {
            margin-right: 30px;
            @media (max-width: 414px) {
               margin-right: 0;
            }
         }
      }
   }
}

#ci {
   @media (max-width: 414px) {
      padding: 1em;
   }

   .mj {
      font-family: 'Iropke Batang', serif;
   }

   .logo-group {
      display: block;
      width:100%;
      background-image: url('/images/ci/bg.png');
      padding: 2em;
   }

   .subject-group {
      p {
         font-family: 'Noto Sans KR', sans-serif;
         font-weight: 300;
         @include media-breakpoint-down(sm) {
            padding: 0 1em;
         }

         .mj {
            font-size: 110%;
            color: $logo1;
         }
      }
   }

   .ci-info-group {

      margin-bottom:20px;

      @include media-breakpoint-up(md) {
         align-items:center !important;
      }

      .thumbnail {
         display: flex;
         border: 1px solid lightgrey;
         align-items:center;
         justify-content: center;
         margin-bottom: 0;
         padding:5px;
         @include media-breakpoint-down(sm) {
            margin-bottom: 20px;
         }
      }

      .info-title {
         font: 600 18px/22px 'Noto Sans KR', sans-serif;
         color: $logo1;
         margin-bottom: 10px;
         position: relative;
      }

      p {
         font-family: 'Iropke Batang', serif;
         word-break: keep-all;
         margin-bottom:0;
      }

      .greek {
         font-family: 'GFS Didot', serif;
      }
   }
}

#blog {
   .file {
      padding: 5px;
   }
}

.bg-blog-show-header {
   position: absolute;
   width: 100%;
   height: 100%;
}

.page-event {
   ul {
      list-style-type: none;
      padding-left: 0;

      li {
         padding: 5px 0;

         .glyphicon {
            display: inline-block;
            text-align: center;
            line-height: 24px;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: $menu-light-grey;
            color: $menu-green;
         }
      }
   }
}

/**************************************
Forest 관련
**************************************/

#forest {
   header {
      height: 60px;
      position: fixed;
      width: 100vw;
      z-index: 900;

      .container {
         height: 60px;
      }
   }

   #forest_header_logo {
      display: block;
      text-align: left;
      padding: 10px 0 10px;
      width: 200px;

      .image-logo {
         @media (max-width: 736px) and (-webkit-min-device-pixel-ratio: 1.3) {
            position: fixed;
            left: 1em;
            top: 14px;
            width: 130px;
            img {
               width: 100%;
            }
         }
         @media (min-width: 737px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.3) {
            position: fixed;
            left: 2em;
            top: 14px;
            width: 130px;
            img {
               width: 100%;
            }
         }
      }
   }

   #forest-nav {
      display: block;
      height: 28px;
      width: 28px;
      position: absolute;
      right: 22px;
      top: 20px;
      z-index: 999;
      font-size: 24px;
      color: $menu-dark-grey;
      transition: color .4s ease;

      > span {
         display: block;
         width: 100%;
         height: 2px;
         margin-bottom: 5px;
         background: $menu-dark-grey;
      }

      @media (min-width: 1025px),
      (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1.3),
      (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 2),
      (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 3) {
         display: none;
      }
   }

   #forest-main-nav {
      position: absolute;
      display: flex;
      list-style-type: none;
      padding-left: 0;
      width: 50%;
      top: 28px;
      right: 32px;
      justify-content: space-between;
      @media (max-width: 1024px),
      (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1.3),
      (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 2),
      (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 3) {
         position: fixed;
         width: 200px;
         right: -200px;
         top: 60px;
         background-color: $menu-light-grey;
         height: calc(100vh - 60px);
         flex-direction: column;
         justify-content: flex-start;
         padding: 15px;
         li {
            padding: 5px 0;
         }
      }

      a {
         color: $menu-dark-grey;
         font-weight: 700;
         font-size: 16px;
         font-family: Comfortaa, cursive;
      }
   }

   .header-slide {
      @media (max-width: 768px) {
         margin-top: 60px;
      }
   }

   .forest-slide {
      height: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      width: 100%;
      position: relative;

      .bg-wrap {
         position: absolute;
         width: 100%;
         height: 100%;
         left: 0;
         top: 0;

         img {
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
            @media (min-width: 1024px),
            (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 1.3),
            (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 2),
            (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 3) {
               top: -10%;
            }
         }
      }

      @media (max-width: 1024px) {
         height: calc(100vw * .48);
      }

      .forest-title {
         font-size: 50px;
         font-family: 'Iropke Batang', serif;
         color: #FFFFFF;
         text-shadow: 0 0 8px #000000;
         letter-spacing: -0.08em;
         text-align: center;
         position: relative;
         z-index: 100;

         @media (max-width: 414px) {
            font-size: 32px;
         }
         @media (min-width: 415px) and (max-width: 768px) {
            font-size: 44px;
         }
      }
   }

   #forest-story {
      padding: 50px 20px;
      font-family: 'Iropke Batang', serif !important;

      a {
         @extend .highlight;
      }

      p {
         font-family: 'Iropke Batang', serif !important;
         font-size: 18px;
         line-height: 2em;
      }
   }

   #forest-middle-banner {
      background-color: $menu-dark-grey;
      color: #FFFFFF;
      padding-top: 50px;
      padding-bottom: 50px;

      h2 {
         @extend .forest-sec-title;
         color: #FFFFFF !important;
      }

      .menu-table {
         border-top: 1px solid $menu-light-grey;
         width: 100%;
         margin-bottom: 30px;

         td {
            padding: 10px;
            vertical-align: top;
         }

         tr.border > td {
            border-top: 1px solid $menu-light-grey;
         }

         tr > td:nth-child(1) {
            font-size: 80%;
         }

         .menu {
            font-size: 120%;
            @media (min-width: 415px) and (max-width: 414px) {
               font-size: 100%;
            }
         }

         .menu-ko {
            font-weight: 700;
         }

         .menu-en {
            font-size: 90%;
            font-weight: 200;
         }

         .price {
            font-size: 130%;
            font-weight: 200;
         }
      }
   }

   .photo-gallery {
      .thumbnail {
         max-height: 173px;
         overflow-y: hidden;
      }
   }

   #forest_footer {
      display: block;
      /*background-color: $forest-green;*/
      /*background-color: #41567b;*/
      background-color: $menu-dark-grey;
      padding-bottom: 0;
      margin-top: 0;

      .logo {
         margin-bottom: 30px;

         > img {
            width: 200px;
            height: auto;
            position: relative;
            top: 20px;
         }

         .footer_bg {
            position: absolute;
            bottom: 0;
            left: 0;
         }
      }

      .information {
         dl {
            padding: 20px;
            margin: 0;

            dt {
               color: $forest-green-text;
               margin-bottom: 10px;
               font-size: 20px;
            }

            dd {
               font-size: 14px;
               font-weight: 300;
            }
         }
      }

      .copyright {
         padding: 20px 0;
         font-size: 12px;
         font-weight: 100;

         a {
            margin-left: 20px;
         }
      }
   }

   .forest-sec-title {
      color: $dark;
      font: 700 32px/1.8em 'Comfortaa', cursive;
      text-align: center;
      width: 100%;
      padding: 1em 0;
   }

   .menu-wrap {
      display: flex;
      justify-content: space-between;
      flex-basis: 1;
      flex-wrap: wrap;

      figure {
         margin-bottom: 40px;

         img {
            display: block;
            width: 100%;
            margin-bottom: 20px;
         }

         figcaption {
            color: $dark;

            .menu-item {
               font-size: 18px;
               font-weight: 300;
            }

            .price {
               font-weight: 400;
            }
         }
      }
   }

   .full-map {
      margin: 0;
   }

   #googleMap {
      width: 100%;
      height: 550px;
      background: $sand-color;
   }

   #mapInfoWindow {
      figcaption {
         display: flex;
         justify-content: space-between;
         flex-basis: 1;

         dl {
            flex-basis: 50%;
            padding: 10px;
         }
      }
   }

   #forest-preorder-wrap {
      padding: 60px 0;

      .form-gap {
         .form-inline {
            margin-bottom: 10px;
         }
      }
   }

   .order-side {
      h4 {
         margin-bottom: 10px;
         padding-bottom: 5px;
         border-bottom: 1px solid $light-dark;
      }

      #kakao-pay {
         display: block;
         width: 100%;
         height: 40px;
         color: #FFFFFF;
         background-color: #FFD919;
         margin-bottom: 5px;
      }

      .checkin-group {
         a {
            display: block;
            width: 100%;
            height: 40px;
            line-height: 40px;
            color: #FFFFFF;
            margin-bottom: 5px;
            text-align: center;

            &:last-child {
               height: 60px;
               line-height: 60px;
            }
         }
      }

      #kakao-pay {
         background-color: #FFD919;

         &:hover {
            background-color: #FFA80C;
            color: $dark;
         }
      }

      #naver-pay {
         background-color: #27B12C;

         &:hover {
            background-color: #3EF03E;
            color: $dark;
         }
      }

      #credit-card {
         background-color: #7F8C99;

         &:hover {
            background-color: #0077AA;
            color: #FFFFFF;
         }
      }

   }
}

// Forest 끝

// 더하우스콘서트
#concert {
   background-color: rgba(161, 183, 179, .1);
}

#concert-header {
   background-color: rgba(0, 0, 0, 0);
   height: initial;
   padding-top: 20px;
   padding-bottom: 20px;

   > .container {
      position: static;
      display: flex;
      justify-content: space-between;
   }

   #header-logo {
      display: block;
      width: 160px;
      @media (max-width: 768px) {
         padding-left: 15px;
         position: absolute;
         left: 0;
      }
      @media (max-width: 480px) {
         width: 140px;
         top: -5px;
      }

      img.logo {
         width: 100%;
         height: auto;
      }
   }

   #concert-nav {
      display: block;
      position: relative;
      @media (max-width: 736px) {
         position: absolute;
         width: 50%;
      }

      .top-level {
         display: flex;
         justify-content: flex-end;
         list-style-type: none;
         padding-left: 0;
         margin: 0;

         .top-child {
            display: inline-block;
            margin-right: 40px;
            font-size: 18px;
            font-weight: 600;
            @media (max-width: 768px) {
               display: none;
            }

            &:last-child {
               margin-right: 0;
               @media (min-width: 481px) {
                  display: none;
               }

               a {
                  display: inline-flex;
                  flex-direction: column;
                  justify-content: space-between;
                  align-items: flex-start;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  left: 0;
                  top: 0;

                  span {
                     display: block;
                     width: 100%;
                     height: 2px;
                     background-color: #d5dddf;
                  }
               }

               @media (max-width: 768px) {
                  display: block;
                  width: 32px;
                  height: 32px;
                  position: absolute;
                  right: 10px;
                  top: 18px;
               }
               @media (max-width: 480px) {
                  position: absolute;
                  right: 1em;
                  top: .8em;
                  width: 24px;
                  height: 20px;
               }
            }

            a {
               color: $menu-light-grey;
               transition: color .4s ease;

               &:hover {
                  color: #E03E23;
               }
            }
         }
      }
   }
}

#concert-mobile-nav {
   position: fixed;
   top: 50px;
   right: 0;
   width: 50%;
   background-color: $menu-light-grey;
   height: calc(100vh - 50px);
   z-index: 100;
   padding: 15px;

   ul {
      list-style-type: none;
      padding-left: 0;
      margin-bottom: 0;

      li {
         font-size: 1em;
         padding: 10px 0;
         text-align: right;

         a {
            color: $menu-dark-grey;
         }
      }
   }
}

#concert-slide {
   display: block;
   background-color: #000000;
   width: 100%;
   margin-top: 50px;

   .desk {
      @media (max-width: 768px) {
         display: none;
      }
      @media (min-width: 769px) {
         display: block;
      }
   }

   .mobile {
      @media (max-width: 768px) {
         display: block;
         img {
            width: 100%;
         }
      }
      @media (min-width: 769px) {
         display: none;
      }
   }
}

#concert-information {
   box-sizing: border-box;
   display: flex;
   justify-content: center;
   align-items: center;
   background-image: url(/images/house-concert/concert-background-80.jpg);
   background-size: cover;
   background-repeat: repeat;
   min-height: 1000px;
   @media (max-width: 480px) {
      min-height: initial;
      padding: 32px;
   }
}

.concert-front-latest .card {
   .thumbnail-img {
      height: 300px;
      background-position-y: 0;
      transition: background-position-y .4s ease;

      &:hover {
         background-position-y: 20%;
      }
   }

   .card-title {
      text-align: center;
      height: initial;

      h4 {
         font-size: 140%;
      }

      p {
         text-align: center;
      }
   }
}

.hashtag {
   /*color:#1F9BE0;*/
   color: #FF4343;
}

.bg-concert-show-header {
   .title-wrap {
      h1 {
         font-size: 32px;
         font-weight: 700;
         color: #FFFFFF;
         text-shadow: 0 0 4px $menu-dark-grey;
         position: relative;
         padding: 10px 40px 20px;
         background-color: rgba(0, 0, 0, .6);

         small {
            display: inline-block;
            padding-bottom: 10px;
            color: #E03E23;
         }
      }
   }
}

#concert-main {
   article {
      display: block;
      width: 100%;
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
      @media(max-width: 768px) {
         padding-left: 15px;
         padding-right: 15px;
      }
   }
}

#sns_share_wrap {
   display: flex;
   justify-content: flex-start;
   @media (max-width: 480px) {
      flex-wrap: wrap;
   }

   div {
      margin-right: 4px;
   }
}

/*소셜 로그인 버튼*/

.social-btn {
   margin-bottom: 5px;
   display: inline-block;
   width: 100%;
   height: 42px;
   line-height: 42px;
   font-size: 14px;
   padding-left: 10px;
   border-radius: 4px;
   position: relative;

   &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 900;
      transform: scaleX(0);
      transition: all .3s ease;
   }

   &:hover {
      &:after {
         transform: scaleX(1);
      }
   }

   .social-icon {
      display: inline-block;
      width: 32px;
      height: 32px;
      margin-right: 10px;
      position: relative;

      img {
         width: 32px;
         height: 32px;
      }

      &::after {
         content: '';
         position: absolute;
         display: block;
         width: 1px;
         height: 100%;
         right: -6px;
         top: 6px;
         background-color: rgba(255, 255, 255, .3);
      }
   }
}

.naver_color {
   background-color: $naver_color !important;
   color: #FFFFFF !important;
}

.facebook_color {
   background-color: $facebook_color !important;
   color: #FFFFFF !important;
}

.google_color {
   background-color: $google_color !important;
   color: #FFFFFF !important;
}

#academies {
   .academy:nth-child(even) {
      .card > .card-header {
         background-color: #f16d2e;
      }
   }
   .academy:nth-child(odd) {
      .card > .card-header {
         background-color:#00b98f;
      }
   }

   .academy {
      .card {
         .card-header {
            color:white;
         }
      }

      .photo {
         width: 80px;
         height: 80px;
         border-radius: 50%;
         overflow: hidden;
      }

      .academy-title {
         font-weight: 600;
         font-size: 20px;
         color: white;
         margin-top: 20px;
         margin-bottom: 20px;
         background-color: #00b98f;
         text-align: center;
         padding: 10px 5px;
      }

      .academy-info {
         li {
            margin-bottom:2px;
            .feather {
               top: -2px;
               margin-right: 4px;
               color: #d43720;
            }

            b {
               color: #d43720;
            }
         }
      }

      .curriculum-title {
         font-size: 18px;
         font-weight: bold;
         position: relative;
         margin-top: 20px;
         margin-bottom: 20px;

         &::after {
            content: '';
            position: absolute;
            display: block;
            height: 1px;
            width: calc(100% - 100px);
            background-color: #666666;
            right: 0;
            top: 10px;
         }

         .feather {
            font-size: .8em;
            top: -2px;
         }
      }

      // 한사랑학당 커리큘럼
      .curriculum-wrapper {
         display: flex;
         flex-direction: column;

         .curriculum-container {
            display: flex;
            justify-content: start;

            .curriculum-item {
               flex: 1;
               padding: 10px;
               border-bottom: 1px solid #d5d6d8;

               &.uploaded {
                  color: #01a98b;
               }

               .feather {
                  top: -2px;
                  width: .9em;
                  height: .9em;
               }

               .play-icon {
                  width: 100px;
                  max-width: 20px;
                  position: relative;
                  top: -2px;
               }

               a {
                  color: black;

                  &:hover {
                     color: #ba2b22;
                     background-color: white;
                  }
               }

            }

            &:last-child {
               .curriculum-item {
                  border-bottom: none;
               }
            }
         }
      }
   }
}

#library {
   .single-gallery-wrap {
      column-count: 4;
      column-gap: 30px;

      .gallery-thumbnail {
         display: inline-block;
      }

      @media (max-width: 480px) {
         column-count: 2;
         column-gap: 10px;
      }
   }
}

// blog show
#gallery-section img:hover {
   transition: all 100ms ease-in-out;
   transform: perspective(400px) rotateY(-45deg);
}

//frontpage latest campaign
.latest-campaign {
   .card {
      .thumbnail-img {
         background-size: cover;
         background-repeat: no-repeat;
      }

      .card-body {
         > small > .meta-data {
            color: #3F957B;
            display: inline-block;
            margin-right: .5em;

            &:last-child {
               margin-right: 0;
            }
         }
      }

      .feather {
         position: relative;
         top: .1em;
         width: 16px;
         height: 16px;
      }
   }
}


.bg-exhibition {
   background: url(/images/welcome/exhibition-part-bg.jpg) no-repeat;
   background-position: center;
   background-size: cover;
   padding-top: 60px;
   padding-bottom: 60px;
   @media screen and (max-width: 480px) {
      padding-top: 20px;
      padding-bottom: 20px;
      max-width: 100vw;
      overflow-x: hidden;
   }
}

#exhibitions-and-concert {
   display: flex;
   flex-wrap: wrap;

   @media screen and (max-width: 480px) {
      padding-left: 1em;
      padding-right: 1em;
   }

   .exhibition-part, .concert-part {
      flex-basis: 70%;
      max-width: 70%;
      padding: 20px 32px;
      border-radius: 16px;
      position: relative;
      background-color: white;
      box-shadow: 0 0 12px rgba(0, 0, 0, .3);
      transition: all 100ms ease-in-out;

      &:hover {
         cursor: pointer;
         transform: scale(1.05);
         z-index: 1020;
      }

      .title {
         font-size: 22px;
         font-weight: 700;
         @include media-breakpoint-down(sm) {
            font-size: 18px;
            text-align: center;
         }
      }

      .label {
         position: absolute;
         width: 240px;

         img {
            width: 100%;
         }
      }

      @media screen and (max-width: 480px) {
         flex: none;
         width: 100%;
         margin-left: 1em;
         margin-right: 1em;
         margin-bottom: 1em;
         max-width: calc(100% - 2em);
         position: static;
         .label {
            position: relative;
            top: 0 !important;
            margin-bottom: 20px;
            width: 100%;
            display: block;

            img {
               width: 100%;
            }
         }
      }
   }

   dl {
      dt {
         color: $logo1;
      }
   }

   .exhibition-part {
      padding-bottom: 52px;

      .thumbnail {
         flex: 1;
         padding: 10px;
         max-width: 250px;

         img {
            width: 100%;
         }
      }

      @media screen and (max-width: 480px) {
         padding-bottom: 20px;
         .d-flex {
            flex-direction: column;
         }
         .thumbnail {
            flex: none;
            width: 100%;
            max-width: 100%;
            text-align: center;

            img {
               display: block;
               margin-left: auto;
               margin-right: auto;
            }
         }
      }

      .exhibition-info {
         flex: 2;
         padding-left: 3rem;
         @media (max-width: 480px) {
            padding-left: 0;
         }
      }

      .label {
         top: 60px;
         right: -200px;
         @media (max-width: 480px) {
            right: initial;
            left: -25% !important;
         }
      }

      &:hover {
         .label {
            transform: rotate(-4deg);
         }
      }
   }

   .concert-part {
      left: 30%;
      top: -42px;

      .poster {
         max-width: 300px;

         img {
            width: 100%;
         }
      }

      .concert-info {
         dl {
            dt, dd {
               text-align: left;
            }
         }
      }

      .label {
         bottom: 60px;
         left: -200px;
         @media (max-width: 480px) {
            left: initial;
            bottom: initial;
            right: -25%;
         }
      }

      &:hover {
         .label {
            transform: rotate(4deg);
         }
      }
   }
}

#financial-report-wrapper {
   @media (max-width: 480px) {
      padding-left: 15px !important;
      padding-right: 15px !important;
      .card {
         margin-bottom: 1em;

         .thumbnail-img, .card-category-name {
            display: none;
         }
      }
   }
}

#livecast-modal {
   .modal-header {
      background: black;
      color: white;
      border-bottom: 1px solid rgba(white, .3);
      @media screen and (max-width: 640px) {
         .modal-title {
            font-size: 16px;
         }
      }
   }

   .modal-body {
      padding: 0;
      background: black;
      color: white;

      .online-offering-information {
         .offering-banner {
            background-color: #165148;
            padding: 10px 20px;
            text-align: center;

            .btn-link {
               color: white;
               font-weight: 700;
               font-size: 20px;
               text-decoration: none;
               background-color: cadetblue;
               padding: 6px 32px;
               border-radius: 32px;
               transition: all 200ms ease-in-out;

               &:focus {
                  color: #0cff0a;
                  outline: none;
                  background-color: #2a5254;
               }

               &:hover {
                  color: #0cff0a;
                  background-color: #2a5254;
               }
            }
         }

         .panel {
            margin-bottom: 0;
         }

         form {
            color: black;

            label {
               font-size: 14px;
               font-weight: 400;
            }

            .form-control {
               border-radius: 0;
               padding: 2px 12px;
            }

            .input-group-addon {
               border-radius: 0;
            }
         }
      }
   }

   .modal-footer {
      background: black;
      color: white;
      border-top: 1px solid rgba(white, .3);
      padding: 0 20px;
      @media screen and (max-width: 640px) {
         font-size: 14px;
      }
   }
}

#live-cast-container {
   background: #08142b;
   color: white;

   a {
      color: white;
   }

   .live-cast-title {
      font-size: 32px;
      font-weight: 700;
      font-style: italic;
      color: white;
      transition: color 300ms ease-in-out;

      &:hover {
         color: rgba(12, 255, 10, 0.62);
      }

      @media screen and (max-width: 640px) {
         font-size: 18px;
      }
   }

   .live-cast-banner-item {
      display: block;
      margin: 0 auto;
   }

   #live-cast-content-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 640px) {
         flex-direction: column;
         padding: 10px 0;
         .blink_light {
            max-width: 80px;
         }
      }
   }
}

@keyframes blinklight {
   from {
      opacity: 1;
   }
   to {
      opacity: .7;
   }
}

.blink_light {
   animation-duration: 1s;
   animation-name: blinklight;
   animation-iteration-count: infinite;
   animation-direction: alternate;
}

#online-offering-container {
   letter-spacing: -0.015em;

   section {
      display: block;
      margin-bottom: 100px;
   }

   h4 {
      display: inline-block;
      font-weight: 700;
      font-size: 24px;
      text-align: center;
      margin-bottom: 20px;
      padding: 10px 30px;
      background-color: #379776;
      color: white;
      border-radius: 30px;
   }

   .lead {
      color: #308865;

      &.lead-info {
         color: #565a61;
         font-weight: 400;
      }
   }

   .lead-first {
      font-size: 24px;
   }

   .border-0 {
      border: none !important;
   }

   .block-1 {
      margin-bottom: 32px;
      justify-content: center;
      @media (max-width: 640px) {
         flex-direction: column;
      }
   }

   .account {
      border: 4px solid #9db69f;
      padding: 30px;
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 640px) {
         margin-right: initial;
         margin-bottom: 20px;
      }
   }

   .account-item {
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 640px) {
         flex-direction: column;
         align-items: center;
      }

      .item {
         margin: 0 10px;

         img {
            width: 180px;
            height: auto;
         }

         .account-no {
            font-size: 1.5em;
         }
      }
   }

   .example {
      > p {
         @media (max-width: 640px) {
            text-align: center;
         }
      }
   }

   .example-wrapper {
      display: flex;
      @media (max-width: 640px) {
         flex-direction: column;
         align-items: center;
      }

      p {
         flex: none;
         width: 32px;
         height: 32px;
         border-radius: 16px;
         text-align: center;
         line-height: 32px;
         margin-right: 20px;
         @media (max-width: 640px) {
            width: 80px;
         }
      }

      ul {
         text-align: left;

         li {
            border-bottom: 1px solid #eee;
            padding: 8px 0;
            color: #565a61;

            &:first-child {
               padding-top: 0;
            }

            &:last-child {
               padding-bottom: 0;
               border-bottom: 0;
            }
         }
      }
   }

   span.no {
      flex: none;
      display: block;
      width: 32px;
      height: 64px;
      background-color: #306055;
      border-radius: 16px;
      color: white;
      text-align: center;
      line-height: 32px;
      font-size: 18px;
      font-weight: 700;
      margin-right: 16px;
   }

   .gap {
      display: block;
      content: ' ';
      height: 32px;

      &.gap-2x {
         height: 64px;
      }
   }

   .rounded {
      border-radius: 6px;
      overflow: hidden;
   }

   #online-offering-confirmation-form {

      .feather {
         width: 1em;
         height: 1em;
      }

      @media (max-width: 640px) {
         select {
            margin-bottom: 5px;
         }
         .btn-action {
            margin: 0 5px;
         }
      }

      .form-section-wrapper {
         display: flex;
         justify-content: space-between;
         @media (max-width: 640px) {
            flex-direction: column;
         }

         .form-section {
            flex-basis: calc(50% - 20px);
            margin-bottom: 20px;
            @media (max-width: 640px) {
               flex-basis: 100%;
            }
         }
      }

      .personal-info {
         display: flex;
         justify-content: space-between;

         .form-group {
            flex-basis: 32%;
         }
      }

      .amount-row {
         display: flex;
         align-items: center;
         justify-content: space-between;

         .form-group {
            flex-basis: 45%;
            @media (max-width: 640px) {
               .input-group {
                  top: -2px;
               }
            }

            &:last-child {
               flex: none;
               width: 1.4em;
            }
         }
      }

      .total-wrap {
         display: flex;

         label {
            flex: none;
            width: 45%;
            text-align: right;
            margin-right: 10px;
            padding-right: 10px;
         }

         input {
            flex-basis: 45%;
         }
      }

      #comment {
         border-width: 4px;
         border-color: #e7f1e8;
      }

      button[type="submit"] {
         width: 180px;
         height: 50px;
         background-color: #418b32;
         color: white;
         border: none;

         &:hover {
            cursor: pointer;
            background-color: #55b641;
         }
      }
   }
}

#donation-receipt-apply-link {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   align-items: center;
   max-width: 200px;
   position: fixed;
   bottom: 35%;
   right: 20px;
   @include media-breakpoint-down(sm) {
      max-width: 140px;
      bottom:30%;
   }

   img {
      width: 100%;
   }

   label {
      @include media-breakpoint-down(sm) {
         font-size: .85em;
      }
   }
}


#prayer-inquiry-popup, #music-score-popup {
   background-color:white;
   width:300px;
   right:0;
   z-index:3000;
   form {
      .form-check-label, a {
         font-size:14px;
         line-height:1;
      }
   }

   @include media-breakpoint-down(sm) {
      width:200px;
      form {
         .form-check-label {
            font-size:12px;
            line-height:1;
         }
      }
   }
}

#prayer-inquiry-popup {
   bottom:200px;
   @include media-breakpoint-down(sm) {
      bottom: 165px;
   }
}

#music-score-popup {
   bottom:80px;
}

#remodeling-info {
   background-size:cover;
   background-position:center bottom;
   a {
      padding-top:40px;
      font-size:34px;
      text-align:center;
      color: white;
      font-weight:bold;
      line-height:1.3;
      &:hover {
         color: lime;
      }

   }
}
#remodeling {
   font-size:24px;
   @include media-breakpoint-down(sm) {
      font-size:18px;
      .container {
         padding-left:20px;
         padding-right:20px;
      }
   }
   section {
      display:block;
      position:relative;
   }

   h2 {
      font-size: 48px;
      font-weight: bolder;
      @include media-breakpoint-down(sm) {
         font-size:32px;
      }
   }

   #why {
      background-size:cover;
      text-align:center;
      padding-top:80px;
      padding-bottom:60px;

      h2 {
         color: #b0dd37;
         text-align:center;
      }

      @include media-breakpoint-down(sm) {
         background-size: contain;
         padding-top:40px;
         padding-bottom:30px;
         p {
            word-break: keep-all;
         }
      }
   }

   #group-intro {
      h2 { color: #ee5019; }
      .icon {
         img { margin-bottom: 20px; }
         .sub-title { font-size: 18px; line-height: 1.3; }
      }

      @include media-breakpoint-down(sm) {
         .icon {
            flex-basis:50%;
            margin-bottom:40px;
            img { margin-bottom: 20px; }
            .sub-title { font-size: 18px; line-height: 1.3; }
         }
      }
   }
   #group1, #group2, #group3, #group4 {
      overflow-y:hidden;
   }

   #group4 {
      background-size:cover;
      background-position:center;
      min-height:587px;
      @include media-breakpoint-down(sm) {
         min-height:200px;
         background-position:center 70% !important;
         margin-bottom:40px;
      }
   }

   .account {
      background-color:#58955a;
      color: white;
      padding:20px;
      max-width:640px;
      font-size:20px;
      margin-left:auto;
      margin-right:auto;
   }
   .impact {
      color: #24a240 !important;
   }
   .bigicon {
      position:absolute;
      bottom: -10%;
      @include media-breakpoint-down(sm) {
         display:none;
      }
      &.icon-left {
         right: 80%;
      }
      &.icon-right {
         left: 80%;
      }
   }
}

#whb-sunday {
   .fs-l {
      font-size: 1.8rem;
      @include media-breakpoint-down(sm) {
         font-size: 1.1rem;
      }
   }
   .flower-right {
      background:url(/images/subpage-header/whb_sunday/flower.png) no-repeat;
      background-size: 70%;
      background-position: 100% 0;

      @include media-breakpoint-down(sm) {
         background-position: right bottom;
      }
   }

   .color-gray {
      color: #636163;
   }

   .section-1 {
      padding-top: 50px;
      padding-bottom: 50px;
   }
   .btn-support {
      padding: 10px 40px;
      font-size: 2em;
      color: #b4891b;
      background: none;
      border: 2px solid #b4891b;
      border-radius: 0;
      &:hover {
         color: white;
         background-color: #b4891b;
      }

      @include media-breakpoint-down(sm) {
         font-size: 1.4rem;
      }
   }
   .section-2 {
      .sub-header-title {
         display: flex;
         align-items:center;
         @include media-breakpoint-down(sm) {
            align-items: flex-start;
         }

         .left {
            flex-basis: 80%;
            img {
               max-width:300px;
               @include media-breakpoint-down(sm) {
                  max-width: 260px;
                  margin-bottom: 20px;
               }
            }
         }
         .right {
            flex-basis: 20%;
         }
      }
      .sub-title {
         font-size: 2em;
         color: #72be44;
         @include media-breakpoint-down(sm) {
            font-size: 1.4em;
         }
      }
      .sub-title+p {
         font-size: 1.2em;
         @include media-breakpoint-down(sm) {
            font-size: 1em;
         }
      }
      .list-items {
         .item {
            display: flex;
            align-items:center;
            margin-bottom: 20px;

            @include media-breakpoint-down(sm) {
               flex-wrap: wrap;
            }

            img {
               display: block;
               flex-basis: 40%;
               max-width: 300px;
               @include media-breakpoint-down(sm) {
                  flex-basis: 100%;
                  max-width: 100%;
                  margin-bottom:20px;
               }
            }
            .text {
               flex-basis: 60%;
               padding-left: 20px;
               @include media-breakpoint-down(sm) {
                  flex-basis: 100%;
                  padding: 0 10px;
                  word-break: keep-all;
               }

               h4 {
                  @extend .mj;
                  font-size: 22px;
               }
               p {
                  margin-bottom: 0;
               }
            }
            &:nth-child(1) {
               .text > h4 {
                  color: #b6a2c5;
               }
            }
            &:nth-child(2) {
               .text > h4 {
                  color: #f496ae;
               }
            }
            &:nth-child(3) {
               .text > h4 {
                  color: #2e3192;
               }
            }
            &:nth-child(4) {
               .text > h4 {
                  color: #73bf44;
               }
            }
            &:nth-child(5) {
               .text > h4 {
                  color: #5bb6d5;
               }
            }
            &:nth-child(6) {
               .text > h4 {
                  color: #a39997;
               }
            }
         }
      }
   }

   .section-3 {
      background-color:#f6f7f9;
      padding-top: 50px;
      padding-bottom: 50px;

      background-image: url(/images/subpage-header/whb_sunday/fbg.png);
      background-size: 40%;
      background-position: right bottom;
      background-repeat: no-repeat;
      @include media-breakpoint-down(sm) {
         background-position: right top;
      }

      .sub-title {
         font-size: 2em;
         color: #c3975e;
         @include media-breakpoint-down(sm) {
            font-size: 1.4em;
            text-shadow: 0 0 4px rgba(white, .8);
         }
      }
      .text-box {
         .item {
            margin-bottom: 30px;
            img {
               display: block;
               padding-bottom: 4px;
               border-bottom: 2px solid #c3975e;
               margin-bottom: 1em;
            }
            ul,  p {
               @extend .color-gray;
               font-size: 1.12em;
            }

            &:nth-child(1) {
               img {
                  max-width: 30%;
                  @include media-breakpoint-down(sm) {
                     max-width: 50%;
                  }
               }
            }
            &:nth-child(2) {
               img {
                  max-width: 40%;
                  @include media-breakpoint-down(sm) {
                     max-width: 70%;
                  }
               }
            }
            &:nth-child(3) {
               img {
                  max-width: 37%;
                  @include media-breakpoint-down(sm) {
                     max-width: 65%;
                  }
               }
            }
         }
      }
   }

   .section-4 {
      background-color: white;
      padding-top:50px;
      padding-bottom: 50px;

      .green {
         color: #71bd43;
      }
      .purple {
         color: #6f519d;
      }
      .bg-green {
         color: white;
         background-color: #71bd43;
      }
      .bg-purple {
         color: white;
         background-color: #6f519d;
      }
      .opacity-50 {
         opacity: .5;
      }

      h4 {
         padding: 20px;
         color: white;
         span {
            @extend .opacity-50;
            float: right;
         }
      }
      .table {
         tr {
            th {
               width:30%;
            }
            td {
               width: 70%;
            }
            @include media-breakpoint-down(sm) {
               th {
                  width:35%;
               }
               td {
                  width: 65%;
               }
            }
         }
      }
   }

}

.popup {
   position: fixed;
   width:100%;
   max-width: 800px;
   left: calc(50% - 400px);
   top: 10%;
   z-index: 5000;
   img {
      width: 100%;
   }
   @include media-breakpoint-down(sm) {
      left: 0;
      top: 51px;
   }
   form {
      background-color: black;
      color: white;
      text-align:center;
      padding: 10px;
   }
}

.card.circle-thumbnail-card {
   margin-bottom:1em;
   &:hover {
      position:relative;
      top:-2px;
      left:-2px;
      transition:all, 500ms;
      @extend .shadow;
   }
   .card-body {
      display:flex;
      .thumbnail-img {
         @extend .rounded, .rounded-circle, .shadow-sm;
         width:48px;
         height:48px;
         flex:none;
      }
      .card-content {
         padding-left:14px;
         .card-category-name {
            font-size:.9em;
            font-weight:600;
            color: #0c5460;
            margin-bottom:.5em;
            .date {
               font-weight: 400;
               font-size:.85em;
            }
         }

         h4 {
            font-size:1em;
            font-weight:600;
         }

      }
   }
}

.mission-card {
   .thumbnail {
      background-repeat:no-repeat;
      background-position:center;
      background-size:cover;
      transition: background-size 1s ease-in;

      &:hover {
         cursor:pointer;
         background-size: 120%;
      }
   }

   .title {
      @extend .font-weight-bold;
      font-size:1.12rem;
      line-height:1.16;

      a {
         font-size: inherit;
         line-height: inherit;
      }
   }
}
