
// Body
$body-bg: #f5f8fa;

// Typography
$font-family-sans-serif: "Spoqa Han Sans Neo", "Noto Sans KR", sans-serif;
$font-family-serif: "MaruBuri", "Iropke Batang", serif;
$font-size-base: 1rem;
$line-height-base: 1.6;
$text-color: #31353C;

// 로고 컬러 테마 //
$white: #fff;
$black: #000;
$default: #40444C;
$logo1: rgb(67, 166, 54);
$logo2: rgb(172, 209, 156);
$logo3: rgb(41, 104, 38);
$logo4: rgb(34, 86, 69);
$logo5: rgb(88, 106, 91);
$logo6: rgb(155, 156, 160);

// 컬러 셋업
$default-color: #95989A;
$orange: rgb(249, 138, 17);
$light-orange: hsla(32%, 100%, 95%, 0.27);
$light2-orange: hsla(32%, 100%, 95%, 0.71);
$grey-bg: #DFE5E8;
$sand-color: #9F958B;
$brown-color: #532F0A;
$dark: #2B2E31;
$light-dark: #657681;
$forest-green: #29554D;
$forest-green-text: #53988B;
$underline: #ffcd53;
$light-grey: #F5F5F5;
$naver_color: rgb(30, 200, 0);
$facebook_color: #3B5998;
$google_color: #DB4437;

// 메뉴바컬러
$menu-dark-grey: #43484E;
$menu-green: #4D7569;
$menu-blue-grey: #A2B5C2;
$menu-light-grey: #D5DDDF;

.soundcloud-wrap {
  margin-top:1em;
  margin-bottom:1em;
}

.feather {
  width:1.2em;
  height:1.2em;
  &.feather-lg {
    width:1.4em !important;
    height:1.4em !important;
  }
  &.feather-inline {
    width:1em !important;
    height:1em !important;
  }
  &.feather-sm {
    width:.85em !important;
    height:.85em !important;
  }
}

.btn {
  .feather {
    width:1em;
    height:1em;
    position:relative;
    top:-0.03em;
  }
}

.bg-black {
  background-color: black !important;
}

.bootstrap-datetimepicker-widget table thead tr:first-child th {
  padding:16px 0 !important;
}

.dticon {
  display:inline-block;
  margin:0;
  width:18px;
  height:18px;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size:18px 18px !important;
  transition: background-size 200ms ease-out;
  &:hover {
    background-color: #eceeef !important;
    background-size:24px 24px !important;
  }
  &.dticon-clock {
    background-image:url(/dticons/clock.png) !important;

  }
  &.dticon-calendar {
    background-image:url(/dticons/calendar.png) !important;
  }
  &.dticon-up {
    background-image:url(/dticons/chevron-up.png) !important;
  }
  &.dticon-down {
    background-image:url(/dticons/chevron-down.png) !important;
  }
  &.dticon-prev {
    background-image:url(/dticons/arrow-left.png) !important;
  }
  &.dticon-next {
    background-image:url(/dticons/arrow-right.png) !important;
  }
  &.dticon-today {
    background-image:url(/dticons/sun.png) !important;
  }
  &.dticon-clear {
    background-image:url(/dticons/trash.png) !important;
  }
  &.dticon-close {
    background:url(/dticons/x.png) !important;
  }
}

.bootstrap-datetimepicker-widget table td.day {
  padding: 7px !important;
}

.font-gothic {
  font-family: $font-family-sans-serif !important;
}
